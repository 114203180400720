import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  filterValue:boolean,
  selectedCourse:number,
  selectedCourseName:string,
  selectedStatusName:string,
  courseDataValue:any,
  studentDetails:any
  studentEngagementData:any;
  studentEngagementHistoryData:any
  searchValue:any
  queryData:any
  viewAll:boolean
  isLoading: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ApiIntegrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiExampleCallId: any;
  getDetails: any;
  studentEngagementId: any;
  engagementHistoryId: any;
  courseData:any
  

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      studentDetails:[],
      courseDataValue:[],
      selectedCourse: 10000,
      selectedCourseName: '',
      selectedStatusName: 'all statuses',
      filterValue:false,
      studentEngagementData:{},
      studentEngagementHistoryData:[],
      searchValue:'',
      queryData:{
        sortBy:'average_course_progress',
        order:'ASC',
        page:1,
        per:5
      },
      viewAll: false,
      isLoading: true

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (this.apiExampleCallId === apiRequestCallId) {
        this.showAlert("Respnce JSON", JSON.stringify(responseJson));
      }
      if(this.getDetails===apiRequestCallId){
          this.setState({studentDetails:responseJson,isLoading: false})
      }
      if(this.studentEngagementId === apiRequestCallId){
        console.log(responseJson,"ResponseJSON")
        this.setState({
          studentEngagementData:responseJson?.data
        })
      }
      if(this.engagementHistoryId === apiRequestCallId){
        this.setState({
          studentEngagementHistoryData:responseJson?.data
        })
      }
      if(this.courseData===apiRequestCallId){
        this.setState({
          courseDataValue:responseJson
        })
      }
      

    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleInputChange = async (event:any) => {
    const value = event.target.value;
    const token = await getStorageData("signupToken");

    this.setState({ searchValue: value }, () => {
        this.debouncedGetStudentTableDetails(token);
    });
};
handleCourseClick = async(id:any,name:any) => {
  const token = await getStorageData("signupToken");
  if(this.state.selectedCourse!=id ){
      this.setState({selectedCourse:id,selectedCourseName:name})
  }
  else{
    this.setState({selectedCourse:0,selectedCourseName:''})
  }
 
  this.getStudentTableDetails(token);
};
handleStatus = async(name:any) => {
  
  const token = await getStorageData("signupToken");
  if(this.state.selectedStatusName!=name ){
      this.setState({selectedStatusName:name})
  }
  else{
    this.setState({selectedStatusName:''})
  }
  this.getStudentTableDetails(token);
};



handleFilter = (event: any) => {
  if (event && event.stopPropagation) {
    event.stopPropagation();
  }

  this.setState((prevState: any) => ({
    filterValue: !prevState.filterValue,
  }));
};

handleOutsideClick = (event: any) => {
  const filterDiv = document.getElementById('filter-dropdown');
  const filterButton = document.getElementById('filter-button');
  if (
    filterDiv &&
    !filterDiv.contains(event.target as Node) &&
    filterButton &&
    !filterButton.contains(event.target as Node)
  ) {
    this.setState((prevState) => ({
     
      filterValue: false,
    }));
  }
};
setQueryData = (queryData:any) =>{
  this.setState({queryData})
}
handleCoursesData = ( token: string) => {

  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: token,
  };

  const coursesRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
  this.courseData = coursesRequest.messageId;

  coursesRequest.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.courseUnit}`
  );

  coursesRequest.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  coursesRequest.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );

  runEngine.sendMessage(coursesRequest.id, coursesRequest);
};
debouncedGetStudentTableDetails = this.debounce((token: string) => {
    this.getStudentTableDetails(token);
}, 1000);

debounce(func: Function, delay: number) {
  let timer: any;
  return function (...args: any[]) {
    clearTimeout(timer);
    timer = setTimeout(() => func(...args), delay);
  };
}
async componentDidUpdate(prevProps: Props, prevState: any) {
  if (
    this.state.filterValue &&
    !prevState.filterValue
  ) {
    document.addEventListener('click', this.handleOutsideClick);
  } else if (
    !this.state.filterValue &&
    prevState.filterValue
  ) {
    document.removeEventListener('click', this.handleOutsideClick);
  }
}
async componentWillUnmount() {
  document.removeEventListener('click', this.handleOutsideClick);
}
   async componentDidMount() {
      super.componentDidMount();
      const token = await getStorageData("signupToken");
     const id = this.props.navigation.getParam('id')
     if (id) {
       this.getStudentDetail(token, id)
       this.getStudentEngagementHistory(token,id)
     } else {
       this.getStudentTableDetails(token);
       this.handleCoursesData(token)
     }
     this.getStudentTableDetails(token);

   }


   getStudentTableDetails = async(token: string) => {
    token = token ? token : await getStorageData("signupToken");
    const {sortBy,order,page,per} = this.state.queryData || {sortBy:'average_course_progress',order:'ASC',page:1,per:5}
    let queryStr  = `sort_by=${sortBy}&order=${order}&page=${page}&per=${per}`
    
     let query = `${configJSON.table}`;

     if (this.state.searchValue) {
       query += `?search_param=${this.state.searchValue}`;
     }

     if (this.state.selectedStatusName) {
       query += query.includes('?') ? `&status=${this.state.selectedStatusName}` : `?status=${this.state.selectedStatusName}`;
     }

     if (this.state.selectedCourse && this.state.selectedCourse!=10000) {
       query += query.includes('?') ? `&course_id=${this.state.selectedCourse}` : `?course_id=${this.state.selectedCourse}`;
     }
     if(queryStr){
      query += query.includes('?') ? `&${queryStr}` : `?${queryStr}`;
     }

     const header = {
       "Content-Type": configJSON.validationApiContentType,
       token: token,
     };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getDetails = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     query
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    const header = {};

    const data = {};

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiExampleCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.exampleAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getStudentDetail(token: string,id:string) {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.studentEngagementId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.studentDetailEndPoint}?account_id=${id}&per=100`
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    
  }

  getStudentEngagementHistory(token: string,id:string) {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.engagementHistoryId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.engagementHistory}?account_id=${id}`
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
  }
  navigateStudentDetail = (id:string) => {
    console.log(this.props.navigation)
    this.props.navigation.navigate(`StudentDetail`,{id})
  }
  backToStudentList = () =>{
    this.props.navigation.navigate('Students')
  }
  // Customizable Area End
}
