// Customizable Area Start
import React from "react";
import {
  // Customizable Area Start
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import LoginComp from "../../signuplogin/src/LoginComp.web";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import './styles/customForm.css';

import CustomFormController, {
  Props, GoalSection, Goal 
} from "./CustomFormController.web";
import { trophy } from "./assets";
import ProgressBar from "./ProgressBar.web";
// Customizable Area End

export default class MyImmediateGoals extends CustomFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.state = {
      ...this.state,
      goalSections: this.generateGoalSections()
    };
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
        <LoginComp
          navigation={undefined} 
          id={""} 
          setup={true}
          onhover={this.handleIconHover}
          onleave={this.handleIconLeave}
          showInfo={this.state.showInfo}
          bgImgColor={'linear-gradient(to bottom, #5591f500 10%, #b429f950, #5591f5)'}
          heading={this.immediateGoalsHeading}
          infoText1={this.immediateGoalsInfoText}
          infoTextStyle={"desc-text"}
          backBtn={true}
          goBack={this.goBack}
          buttonText={'Next'}
          btnDisabled={this.state.immediateGoalBtn}
          onclick={this.handleImmediateGoal}
        
          customForm={
            <ProgressBar
              navigation={undefined}
              id={""}
              role={true}
              vertical={true}
              journey={true}
              institution={true}
              immediateGoal={this.state.immediateGoalStep}
              futureGoal={false}
              step1={true}
              step2={true}
              step3={true}
              step4={true}
              step5={false}
              step6={false}
            />
          }
          formContent={
            <div className="goal-container" data-testid="goal-container">
         {this.state.goalSections?.map((section: GoalSection, sectionIndex: number) => (      
    <Accordion key={sectionIndex} style={{ background: 'transparent', boxShadow: 'none' }}>
        <AccordionSummary
            expandIcon={<h6 style={{ color: 'white' }}><ArrowDropDownIcon /></h6>}
            aria-controls={`panel${sectionIndex}-content`}
            id={`panel${sectionIndex}-header`}
        >
            <Typography style={{ color: 'white' }}>{section.category}</Typography>
            <div style={{
                border: '1px solid #FFFFFF',
                width: '562px',
                height: '0px',
                position: 'absolute',
                bottom: '-3px',
                
                opacity: '0.1',
                transform: 'rotate(0deg)',
            }}></div>
        </AccordionSummary>
        <AccordionDetails>
            <div style={{ width: "100%" }}>
                {section && section.goals && section.goals?.map((goal, index) => (
                    <div 
                        key={goal?.id} 
                        className={`goal-wrapper ${this.state.checkedItems.some((item: { id: string; }) => item.id === goal.id.toString()) ? "selected-goal-wrapper" : ""}`} 
                        data-testid={`goal${goal?.id}-wrapper`}
                    >
                        <label className="goal-label" htmlFor={`goal-${goal?.id}`} data-testid={`goal-${goal?.id}`}>
                            <span 
                                className={`goal-icon ${this.state.checkedItems?.some((item: { id: string; }) => item?.id === goal?.id?.toString()) ? "selected-goal-icon" : ""}`} 
                                data-testid="iconWrapper"
                            >
                                <img src={trophy} alt="trophy-icon" data-testid="trophy-icon" />
                            </span>
                            <input
                                type="checkbox"
                                id={`goal-${goal?.id}`}
                                name={goal?.id?.toString()}
                                 value={goal?.title}
                                className="goal-checkbox"
                                onChange={this.handleGoalChange}
                                checked={this.state.checkedItems?.some((item: { id: string; }) => item?.id === goal?.id?.toString())}
                                data-testid={`goal${goal?.id}-input`}
                            />
                            {goal?.title}
                        </label>
                    </div>
                ))}
            </div>
        </AccordionDetails>
    </Accordion>
))}

            </div>
          }
        />
      // Customizable Area End
    );
  }
}
// Customizable Area End
