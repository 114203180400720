import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import firebase from "firebase";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  exportsValue:boolean,
  loading:boolean,
  searchValue:string,
  scoreValues:string, 
  metricReport:any,
  averageValues:any,
  csvData:any,
  txtInputValue:string;
  viewAll:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AnalyticsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  metricsdata: any;
  averageScoreValues:any;
  csvValue:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess)
      // Customizable Area Start
     , getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      exportsValue:false,
      searchValue:'',
      loading:false,
      metricReport:[],
      scoreValues:'All', 
      averageValues:[],
      csvData:[],
      txtInputValue:"",
      viewAll: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
    if (firebase.apps.length !== 0) {
      const defaultAnalytics = firebase.app().analytics();
      defaultAnalytics.logEvent("Analytics::Web::Load");
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (apiRequestCallId === this.metricsdata) {
    
     this.setState({metricReport:responseJson})
    } 
    else if(apiRequestCallId===this.averageScoreValues){
      this.setState(
        { averageValues: responseJson?.data },
        () => this.setState({ loading: false }) 
      );

    }
    else if(apiRequestCallId===this.csvValue){
      this.setState({csvData:responseJson})
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  async doButtonPressed() {
    const defaultAnalytics = firebase.app().analytics();
    defaultAnalytics.logEvent("Analytics::Web::button_clicked");
  }

  // Customizable Area Start
  handleCsvFile=(token:any)=>{
    const csvUrl = `${this.state.csvData?.csv_download_url}?token=${token}`
    console.log(`${this.state.csvData?.excel_download_url}?token=${token}`,"debugging")
    if (csvUrl) {
    const link = document.createElement("a");
    link.href = csvUrl;
    link.setAttribute("download", "data.csv"); 
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  }
  handleExcelFile=(token:any)=>{
    const excelUrl = `${this.state.csvData?.excel_download_url}?token=${token}`
    if (excelUrl) {
    const linkExcel = document.createElement("a");
    linkExcel.href = excelUrl;
    linkExcel.setAttribute("download", "data.xlsx"); 
    document.body.appendChild(linkExcel);
    linkExcel.click();
    document.body.removeChild(linkExcel);
   }
  }
   handleColor=(rate:any)=>{
     if(rate>=0 && rate<=49){
      return 'rgba(248, 113, 113, .4)';
     }
     else if(rate>=50 && rate<=69){
      return 'rgba(245, 158, 11, .4)'
     }
     return 'rgba(114, 239, 221, 0.4)'
   }
  async componentDidMount() {
    super.componentDidMount();
    this.scoreChart("All");
    const token = await getStorageData("signupToken");
    this.getMetricsData(token);
    this.getAveragScore(token);
    this.handleCsv(token);
  }
  async scoreChart(value:any){
    this.setState({scoreValues:value})
    const token = await getStorageData("signupToken");
    this.getAveragScore(token);
   }

  handleExport = () => {
    this.setState((prevState) => ({ exportsValue: !prevState.exportsValue }));
  }
  handleInputChange = async (event:any) => {
    const value = event.target.value;
    const token = await getStorageData("signupToken");

    this.setState({ searchValue: value }, () => {
        this.debouncedGetStudentTableDetails(token);
    });
    
};

debouncedGetStudentTableDetails = this.debounce((token: string) => {
  this.getAveragScore(token);
}, 1000);

debounce(func: Function, delay: number) {
  let timer: any;
  return function (...args: any[]) {
    clearTimeout(timer);
    timer = setTimeout(() => func(...args), delay);
  };
}
   handleCsv=(token:string)=>{
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: token,
      };
  
      const averageScore = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.csvValue= averageScore.messageId;
  
      averageScore.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.performance}`
      );
  
      averageScore.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      averageScore.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
  
      runEngine.sendMessage(averageScore.id, averageScore);
  
  }

  
  getAveragScore=(token: string)=>{
    this.setState({loading:true})
  let query=`${configJSON.averagData}${this.state.scoreValues?.toLowerCase()}`
    if (this.state.searchValue) {
      query += `&search_param=${this.state.searchValue}`;
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const averageScore = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.averageScoreValues= averageScore.messageId;

    averageScore.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      query
    );

    averageScore.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    averageScore.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(averageScore.id, averageScore);
}
  getMetricsData=(token: string)=>{
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const metricsRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.metricsdata = metricsRequest.messageId;

    metricsRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.metricsData}`
    );

    metricsRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    metricsRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(metricsRequest.id, metricsRequest);
}
  // Customizable Area End
}
