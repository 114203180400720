import React, { useState } from "react";
import { 
  Box, 
  Typography 
} from "@mui/material";
import CircleWithImage from "./CircleImage.web";
import { arrowDown } from "./assets";

const EngagementHistory = (props:any) => {
  const [viewAll, setViewAll] = useState(false)

  const mapUserInteractionsToTimeline = (data:any) => {
    const groupedData = {} as any;
  
    data?.forEach((item:any) => {
      const { 
        created_at,
        message, 
        sub_category_id, 
        image 
      } = item.attributes;
      let date = new Date(created_at).toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });
       date = date.replace(/(\w{3})(\s\d{4})$/, '$1,$2');

      console.log(date,"datedate")
      const time = new Date(created_at).toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
      });
  
      let type = "other";
      if (message) {
        if (message.includes("logged in")) type = "login";
        else if (message.includes("logged out")) type = "logout";
        else if (message.includes("started course")) type = "course";
      }

      if (!groupedData[date]) {
        groupedData[date] = [];
      }
  
      groupedData[date].push({
        time,
        text: message,
        type,
        image: image ? image.url : "",
      });
    });
  
    return Object.entries(groupedData).map(([date, events]) => (
      { date, events }
    ));
  };
  const engagementHistory = mapUserInteractionsToTimeline(props?.engagementHistoryData?.slice(0,viewAll ? props?.engagementHistoryData?.length : 6) ) || []
  return (
    <Box sx={{ pl:2, pr:2, maxWidth: 500, borderRadius: 3, color: "#fff" }}>
      <Typography variant="h5" sx={{ fontWeight: "bold", mb: 5 }}>
        Engagement History
      </Typography>

      {engagementHistory.map((section:any, index) => (
        <Box key={index} sx={{ mb: 2 }}>
          <Typography variant="body1" sx={{ fontWeight: "500", mb: 1, color: "#fff",fontSize:'14px',fontFamily:'Poppins' }}>
            {section.date}
          </Typography>

          <Box sx={{ display: "flex", flexDirection: "column", position: "relative" }}>
            {/* Timeline Vertical Line */}
            <Box
              sx={{
                position: "absolute",
                left: "12px",
                top: 0,
                bottom: index === engagementHistory.length-1 ? '60px' : '0px',
                width: "1.5px",
                backgroundColor: "#232E3F",
              }}
            />

            {section.events.map((event:any, idx:number) => (
              <Box
                key={idx}
                sx={{
                  alignItems: "center",
                  mb: 2,
                  position: "relative",
                  flexDirection: "row", // Items will always be in a row
                  textAlign: "left"
                }}
              >
                {/* Timeline Dot (Fixed Position) */}
                <CircleWithImage imageUrl={event.image} borderColor="#3B7375" letter={props.studentName && props.studentName[0].toUpperCase()} isLast={event.type==='login'}/>

    

                {/* Event Content */}
                <Box sx={{ ml: 2, position: 'relative', left: '20px', top: '-22px' }}>
                  <Box  sx={{ color: "#ddd" }}>
                    <p style={{display:'flex',gap:'24px'}}><span style={{fontWeight:400,fontSize:'14px',fontFamily:'Poppins',color:'#B9BBBE'}}>{event.time}</span> <span style={{fontWeight:400,fontSize:'14px',fontFamily:'Raleway',color:'#fff'}}>{event.text}</span></p>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      ))}
      {props?.engagementHistoryData?.length > 6 && (<Box style={{ display: 'flex', justifyContent: 'center', gap: '8px', alignItems: 'center', marginTop: '12px' }}>
        <Typography style={{
          color: 'rgba(100, 116, 139, 1)',
          fontFamily: 'Raleway',
          fontSize: '14px',
          fontWeight: 800,

        }}>{!viewAll ? 'View all' : 'View less'}</Typography>
        <span test-id="viewAllBtn" onClick={() => setViewAll(!viewAll)} style={viewAll ? { transform: 'rotate(180deg)', cursor: 'pointer' } : { cursor: 'pointer' }}><img src={arrowDown} /></span>
      </Box>)}
    </Box>
  );
};

export default EngagementHistory;
