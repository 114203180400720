import React from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Avatar,
  MenuItem,
  Select,
  InputAdornment,
  IconButton,
} from "@mui/material";
import UserProfileSettingController, { Props } from "./UserProfileSettingController";
import TopNav from './TopNav.web'
import InstitutionalHeader from "../../navigationmenu/src/InstitutionalHeader.web";
import './Header.css'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { deletePng } from "./assets";
import { IoCloseCircleOutline } from "react-icons/io5";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { IoEyeOutline } from "react-icons/io5";
import { IoEyeOffOutline } from "react-icons/io5";

import { PiWarningCircleBold } from "react-icons/pi";


class ProfileSettings extends UserProfileSettingController {
  constructor(props: Props) {
    super(props);

  }

 
  inputTitle = (name: string,errorKey?:any) => {
    const error = this.state.error as any;
    const isError = error[errorKey];
    return (
        <Typography
          sx={{
            position: "absolute",
            top: "-10px",
            left: "15px",
            backgroundColor: "rgb(18,28,40)",
            paddingX: "6px",
            color: isError ? "red" : "white",
            fontSize: "14px",
            fontWeight: "700",
            fontFamily:'Raleway'
          }}
        >
          {name}
        </Typography>

    )
  }
  inputField = (key: string, onChange:any) =>{
    return(<TextField
      variant="standard"
      required={true}
      name={key}
      fullWidth
      InputProps={{
        disableUnderline: true,
        style: {
          color: "white",
          fontSize: "16px",
          padding: "8px 18px",
          fontFamily: 'Poppins',
          fontWeight: '300'
        },
      }}
      sx={{
        mt: 1,
        "& input": { color: "white" },
      }}
      value={this.state[key]}
      onChange={(e:any) => this.isValid(e.target.value) && onChange(e)}
    />)
  }

/* istanbul ignore next */
  inputField2 = (key: string, onChange:any) =>{
    return(<TextField
      variant="standard"
      name={key}
      fullWidth
      required={true}
    
      InputProps={{
        disableUnderline: true,
        style: {
         
          fontFamily: 'Poppins',
          fontWeight: '300',
          color: "white",
          fontSize: "16px",
          padding: "8px 18px",
        },
      }}
      sx={{
        mt: 1,
        "& input": { color: "white" },
      }}
      value={this.state[key]}

      onChange={(e:any) =>  onChange(e)}
    />)
  }

  inputPasswordField = (key: string, onChange:any) =>{
  
    return(<TextField
      variant="standard"
      type={this.state.passwordVisibility[key] ? "text" : "password"}
      name={key}
      fullWidth
      InputProps={{
        disableUnderline: true,
        style: {
          color: "white",
          fontSize: "16px",
          padding: "8px 18px",
          fontFamily: 'Poppins',
          fontWeight: '300'
        },
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() =>this.passwordVisibility(key)}
              edge="end"
              style={{ color: "white",marginBottom:'4px' }}
            >
              {this.state.passwordVisibility[key] ? <IoEyeOffOutline /> : <IoEyeOutline />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      
      sx={{
        mt: 1,
        "& input": { color: "white" },
      }}
      value={this.state[key]}
      onChange={onChange}
    />)
  }
  renderPopupError = () => {
    const successMsg = this.state.succesMsg
    const errorMsg = this.state.error.popupError
    const msg = errorMsg || successMsg
    return (
      <Box
        style={{backgroundColor: `${errorMsg ? '#fee2e2' : '#D1FAE4'}`, borderLeftColor: `${errorMsg ? '#dc2626' : '#D1FAE4'}`}}
        className={`popup-error popupErrorStyle-profile`}
        data-testid="popup-error"
      >
        {errorMsg ? <IoCloseCircleOutline  color="#dc2626" size="22" /> : <IoCheckmarkCircleOutline  color="#258964" size="22" />}
        <Typography style={{ color: `${errorMsg ? '#ff0000' : '#258964'}`}} className="popupError-text">
         {msg}
        </Typography>
      </Box>
    );
  };
  render() {
    const error = this.state.error as any
    return (
      <Box style={webStyle.backgroundCLr} className="course-page">
        <InstitutionalHeader navigation={this.props?.navigation} id={"3"} />
        <TopNav />
        {(this.state.error.popupError || this.state.succesMsg)&& this.renderPopupError()}
        <Box className="highlightMainContainer" style={{ marginTop: "40px", border: '1px solid rgba(30, 41, 59, 1)' }}>
          <Box>
          <Box style={webStyle.rowDirection}>
            <Box>
              <Typography variant="h5" style={{ ...webStyle.subHeadingText, marginLeft: "10px", marginBottom: '8px' }}>
                My Profile
              </Typography>
              <Typography variant="h5" style={{ ...webStyle.emailTxt, marginLeft: "10px", }}>
              {this.state.email}
              </Typography>

            </Box>

          </Box>
          <form >
          <Box style={{ display: 'flex', gap: '28px', marginTop:'28px' }}>
            <Box style={{ marginTop: '12px' }}>
              <Box
                sx={{
                  position: "relative",
                  width: "338px",
                  height: "132px",
                  padding: 3,
                  borderRadius: 3,
                  border: "1.5px solid rgba(255, 255, 255, 0.2)",
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                {/* Avatar Label */}
                <Typography
                  sx={{
                    position: "absolute",
                    top: "-10px",
                    left: "15px",
                    backgroundColor: "rgb(18,28,40)",
                    paddingX: "6px",
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "700",
                    fontFamily:'Raleway'
                  }}
                >
                  Avatar
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  {/* Avatar Image */}
                  <Box>
                    <Avatar sx={{ width: 76, height: 76, backgroundColor:'#404953' }} src={this.state.avatar}>
                      {this.state.firstName[0]?.toUpperCase()}
                    </Avatar>

                    <input
                      accept="image/*"
                      style={{ display: "none" }}
                      id="avatar-upload"
                      type="file"
                      onChange={this.handleAvatarChange}
                    />
                  </Box>

                  {/* Upload & Remove Buttons */}
                  <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                    <label htmlFor="avatar-upload">
                      <Button
                        component="span"
                        variant="contained"
                        sx={{
                          background: "linear-gradient(90deg, #6200EA 0%, #26C5F3 100%)",
                          color: "#FFFFFF",
                          textTransform: "none",
                          paddingX: 2,
                          borderRadius: "20px ",
                          minWidth: "180px",
                          fontSize: "14px",
                          fontWeight: "700",
                          fontFamily: 'Raleway',
                        }}
                      >
                        Upload new image
                      </Button>
                    </label>
                    <div style={{zIndex:2}}>
                      <span className="btn-login-wrapper">
                        <button
                          className="login-btn"
                          style={{
                            color: "gray",
                            fontSize: "14px",
                            fontWeight: "700",
                            fontFamily: 'Raleway',
                            border: 'none',
                            marginLeft: '24px',
                            marginTop: '2px'


                          }}
                          onClick={() => this.setState({ avatar: undefined })}
                          type="button"
                        >
                          Remove avatar
                        </button>
                      </span>
              </div>
                    
                  </Box>
                </Box>
              </Box>

            </Box>
            <Box style={{ marginTop: '12px', display: 'flex', flexDirection: 'column', gap: '32px' }}>
              <Box style={{ display: 'flex', justifyContent: 'space-around', gap: '24px' }}>
                <Box
                  sx={{
                  
                    border: `1px solid ${error.firstName ? 'red' :  'rgba(255, 255, 255, 0.2)'}`,
                  }}
                  className="inputText"

                >
                  {/* Input Label (Floating Effect) */}
                  {this.inputTitle('First Name','firstName')}
                  {this.inputField('firstName', this.handleInputChange)}

                </Box>
                <Box
                  sx={{
                    border: `1px solid ${error.lastName ? 'red' :  'rgba(255, 255, 255, 0.2)'}`,
                  }}
                  className="inputText"
                >
                  {this.inputTitle('Last Name','lastName')}
                  {this.inputField('lastName',this.handleInputChange)}
                </Box>
              </Box>
              <Box style={{ display: 'flex', gap: '16px' }}>
                <Box
                  sx={{
                    position: "relative",
                    width: "340px",
                    borderRadius: "24px",
                    border: `1px solid ${error.title ? 'red' :  'rgba(255, 255, 255, 0.2)'}`,
                  }}
                >
                  {this.inputTitle('Title','title')}
                  {this.inputField2('title', this.handleInputChange)}
                </Box>
              </Box>

            </Box>
           

              </Box>
              <Box style={{ display: "flex", gap: '28px', marginTop: '16px', flexDirection: 'row-reverse' }}>
                <Button
                  component="span"
                  variant="contained"
                  id="savedata"
                  sx={{
                    background: "linear-gradient(90deg, #6200EA 0%, #26C5F3 100%)",
                    color: "#FFFFFF",
                    textTransform: "none",
                    paddingX: 2,
                    borderRadius: "20px",
                    minWidth: "338px",
                    height: '45px',
                    fontWeight: "800",
                    fontFamily: "Raleway",
                    fontSize: "16px",
                    opacity: (this.state.error.firstName || this.state.error.lastName) ? 0.5 : 1

                  }}
                  onClick={this.updateProfileDetail}
                  disabled={this.state.error.firstName || this.state.error.lastName|| this.state.error.title}
                >
                  Save changes
                </Button>

                <Button
                  variant="outlined"
                  sx={{
                    borderColor: "rgba(255, 255, 255, 0.5)",
                    color: "#F87171",
                    textTransform: "none",
                    borderRadius: "20px",
                    border: 'none',
                    "&:hover": {
                      border: 'none'
                    }
                  }}
                >
                  <div style={{}}> <img src={deletePng} />  <span className="deleteTxt" style={{textAlign: 'center' }}>Delete account</span></div>
                </Button>
              </Box>

         </form>
         </Box>
         <Box
          sx={{
            marginTop:'48px',
            border: '1px solid rgba(30, 38, 51, 1)'
          }}></Box>
         <Box mt={4} >
          <Box style={webStyle.rowDirection}>
            <Box>
              <Typography variant="h5" style={{ ...webStyle.subHeadingText, marginLeft: "10px", marginBottom: '8px' }}>
                Change Password
              </Typography>
            

            </Box>


          </Box>
          <Box style={{ marginTop:'28px' }}>
           
            <Box style={{ marginTop: '12px', display: 'flex', flexDirection: 'column', gap: '32px' }}>
              <Box style={{ display: 'flex',  gap: '32px' }}>
                <Box
                  sx={{
                
                    border: "1px solid rgba(255, 255, 255, 0.2)",
                  }}
                  className="inputText"

                >
                  {/* Input Label (Floating Effect) */}
                  {this.inputTitle('Current Password')}
                  {this.inputPasswordField('currentPassword', this.handleInputChange)}

                </Box>
                <Box
                  sx={{
                  
                    border: "1px solid rgba(255, 255, 255, 0.2)",
                  }}
                  className="inputText"

                >
                  {this.inputTitle('New Password')}
                  {this.inputPasswordField('newPassword',this.handleInputChange)}
                </Box>

                <Box
                  sx={{
                 
                    border: "1px solid rgba(255, 255, 255, 0.2)",
                  }}
                  className="inputText"

                >
                  {this.inputTitle('Confirm Password')}
                  {this.inputPasswordField('confirmPassword',this.handleInputChange)}
                </Box>
              </Box>
           

            </Box>
           
          
          </Box>
          <Box style={{ display: "flex",  gap: '32px', marginTop:'32px',flexDirection:'row-reverse' }}>
                      <Button
                        component="span"
                        variant="contained"
                        id="changePassword"
                        sx={{
                          background: "linear-gradient(90deg, #6200EA 0%, #26C5F3 100%)",
                          color: "#FFFFFF",
                          textTransform: "none",
                          paddingX: 2,
                          borderRadius: "20px",
                          minWidth: "338px",
                          marginRight:'6px !important',
                          height:'45px',
                          fontWeight: "800",
                          fontFamily:"Raleway",
                          fontSize: "16px",
                          opacity:this.updatePassBtnDisable() ? 0.5 :1,
                          paddingRight:'16px'

                        }}
                        onClick={this.updatePassword}
                        disabled={this.updatePassBtnDisable()}
                      >
                      Change Password
                      </Button>

                  
          </Box>
          </Box>
        </Box>
      </Box>

    );
  }
}

const webStyle = {
  backgroundCLr: {
    backgroundColor: "rgb(28, 34, 53)",
    minHeight: "100vh",
  },
  text: {
    fontWeight: "bold",
    marginLeft: '10px'
  },

  rowDirection: {
    display: "flex",
    justifyContent: "space-between",
  },
  subHeadingText: {
    fontFamily: "Raleway",
    fontSize: "20px",
    fontWeight: 700,
    color: "rgba(255,255,255,1)",
  },
  emailTxt: {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 300,
    color: "#FFFFFF",
  },
  inputText:{
    
    "@media (max-width: 830PX)": {
        width:'auto'
      },
  }
};
export default ProfileSettings;
