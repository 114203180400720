import React, { CSSProperties } from "react";

// Customizable Area Start
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import {
  Container,
  Box,
  Typography,
  Menu,
  MenuItem,
  ListItemText,
  ThemeProvider,
  Grid,
} from "@material-ui/core";
import { createTheme, withStyles } from "@material-ui/core/styles";
import { logout, medEdsmall } from "./assets";

const StyledMenuItem = withStyles((theme) => ({
  "& .MuiMenu-list,& .MuiList-root,& .MuiMenu-list": {
    paddingBottom: "0px !important",
    paddingTop: "0px !important",
  },
  root: {
    "&.clicked": {
      background:
        "linear-gradient(76.8deg, rgb(121, 45, 129) 3.6%, rgb(36, 31, 98) 90.4%)",
      "& .MuiListItemText-primary": {
        color: theme.palette.common.white,
        fontWeight: 700,
        fontFamily:'Poppins'
      },

    },
    "& .MuiTypography-body1":{
    fontFamily:'Poppins !important'
  },
    "&:hover": {
      background:
        "linear-gradient(76.8deg, rgb(121, 45, 129) 3.6%, rgb(36, 31, 98) 90.4%)",
    },

    padding: "15px 30px !important",
    backgroundColor: "rgba(30, 41, 59, 1)",
  },
  "&:paper": {
    // Override styles for MuiPaper
    opacity: 1,
    transform: "none",
    transition:
      "opacity 217ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 145ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    top: 100,
    left: 197,
    transformOrigin: "66.5px 0px 0px",
  },
}))(MenuItem);

const StyledMenu = withStyles({
  paper: {
    opacity: 1,
    borderRadius: 20,
    backgroundColor: "rgba(30, 41, 59, 1)",
    transform: "none",
    transition:
      "opacity 217ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 145ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    top: "100px !important",
    left: "300px !important",
    transformOrigin: "66.5px 0px 0px",
    color: "white", // Example override for text color
  },
  "& .MuiMenuItem-root": {
    "&:hover": {
      backgroundColor: "black !important", // Change hover background color
    },
  },
})((props: any) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const theme1 = createTheme({
  overrides: {
    MuiList: {
      root: {
        padding: "0px !important", // override padding for MuiList
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: "blue", // set background color for MuiPaper
        top: "100px",
      },
    },
  },
});
// Customizable Area End

import Catalogue1Controller, { Props } from "./Catalogue1Controller";
import "./Header.css";
import FooterController from "./FooterController.web";
import DialogBox from "../../../components/src/DialogBox.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class Header extends FooterController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

 

  handleClose = (menuType: any) => {
    this.setState({valueBorder:0})
    if (menuType === "tools") {
      this.setState({ anchorElTools: null });
    } else if (menuType === "services") {
      this.setState({ anchorElServices: null });
    }
  };
  displayMenu = () => {
    const {
      anchorElHome,
      anchorElTools,
      anchorElServices,
      anchorElhelp,
      selectedMenu,
       valueBorder,
      
    } = this.state;
    return (
      <div style={{ display: "flex",marginLeft:'20px' }}>
        <div
          style={{ position: "relative" }}
          data-test-id="onClickHome"
          onClick={() => this.onClickHome()}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              onClick={(event) => this.handleClick(event, "home")}
              data-test-id="handleClickHome"
              className="displayMenuText instituteText"
              style={this.getMenuStyle(selectedMenu == 1, this.state.valueBorder==1,anchorElHome)}
            >
              Home
            </Typography>
            {selectedMenu == 1 ? (
              <p
                style={{
                  width: "5px",
                  height: "5px",
                  borderRadius: "50%",
                  backgroundColor:
                    anchorElHome || selectedMenu == 1
                      ? "white"                       
                      : "rgba(255, 255, 255, 0.4)",
                  color: "white",
                  marginLeft: "-30px",
                  marginTop:"-6px"                                   
                }}
              />
            ) : null}
          </Box>
        </div>
        <div style={{marginLeft:'-0.5rem'}}>
          <Typography
            onClick={(event) => this.handleClick(event, "tools")}
            data-test-id="handleClickTools"
            className="displayMenuText"
            style={{
              ...this.getMenuStyle(selectedMenu == 2,valueBorder==2, anchorElHome),
              marginLeft: "auto",
            }}
          >
            My Tools
          </Typography>
          {selectedMenu == 2 ? (
            <p
              style={{
                width: "5px",
                height: "5px",
                borderRadius: "50%",
                backgroundColor:
                  anchorElTools || selectedMenu == 2 
                    ? "white"
                    : "rgba(255, 255, 255, 0.4)",
                color: "white",
                marginLeft: "50px", 
                marginTop:"-6px"            
              }}
            />
          ) : null}
          <StyledMenu
            id="customized-menu-tools"
            keepMounted
            MenuListProps={{ autoFocusItem: false }}
            anchorEl={anchorElTools}
            open={Boolean(anchorElTools)}
            data-test-id="handleCloseTools"
            onClose={() => this.handleClose("tools")}
          >
            <StyledMenuItem
              data-test-id="handleScheduleClick"
              className={localStorage?.getItem("selectedIndex") == "10" ? "clicked" : ""}
              onClick={() => {
                this.handleScheduleClick();
                this.handleClose("tools")
              }}
            >
              <ListItemText primary="My Schedule" />
            </StyledMenuItem>
            <StyledMenuItem  data-test-id="handleScheduleClick2" onClick={()=>{this.handleColor("9");
              this.handleClose("tools")
            }
            } className={localStorage?.getItem("selectedIndex") == "9" ? "clicked" : ""} >
              <ListItemText primary="My Tasks" />
            </StyledMenuItem>
            <StyledMenuItem   data-test-id="handleScheduleClick3" onClick={()=>{this.handleColor("8");
              this.handleClose("tools")}} className={localStorage?.getItem("selectedIndex") == "8" ? "clicked" : ""}>
              <ListItemText primary="My Files" />
            </StyledMenuItem>
          </StyledMenu>
        </div>

        <div>
          <Typography
            onClick={(event) => this.handleClick(event, "services")}
            data-test-id="handleClickServices"
            className="displayMenuText"
            style={this.getMenuStyle(selectedMenu == 3,valueBorder==3, anchorElHome)}
          >
            My Services
          </Typography>
          {selectedMenu == 3 ? (
            <p
              style={{
                width: "5px",
                height: "5px",
                borderRadius: "50%",
                backgroundColor:
                  anchorElServices || selectedMenu == 3
                    ? "white"
                    : "rgba(255, 255, 255, 0.4)",
                color: "white",
                marginLeft: "50px",    
                marginTop:'-6px'       
              }}
            />
          ) : null}

          <StyledMenu
            id="customized-menu-services"
            keepMounted
            anchorEl={anchorElServices}
            open={Boolean(anchorElServices)}
            data-test-id="handleCloseServices"
            MenuListProps={{ autoFocusItem: false }}
            onClose={() => this.handleClose("services")}
          >
            <StyledMenuItem
              data-test-id="onMenuItemClick" 
              className={localStorage?.getItem("selectedIndex") == "50" ? "clicked" : ""}
              onClick={() => {
                this.onMenuItemClick();
                 this.handleColor("50");
                 this.handleClose("services")
              }}
            >
              <ListItemText primary="Knowledge & Training Center" />
            </StyledMenuItem>
            <StyledMenuItem  data-test-id="handleScheduleClick20"  onClick={()=>{this.handleColor("60");
              this.handleClose("services")}} className={localStorage?.getItem("selectedIndex") == "60" ? "clicked" : ""}>
              <ListItemText  primary="Healthcare VR/AR & Gaming" />
            </StyledMenuItem>
            <StyledMenuItem  data-test-id="handleScheduleClick200" onClick={()=>{this.handleColor("80");
              this.handleClose("services")}} 
              className={localStorage?.getItem("selectedIndex") == "80" ? "clicked" : ""}>
              <ListItemText primary="Marketplace" />
            </StyledMenuItem>
            <StyledMenuItem   data-test-id="handleScheduleClick2000" onClick={()=>{this.handleColor("90");
            this.handleClose("services")}} 
            className={localStorage?.getItem("selectedIndex") == "90" ? "clicked" : ""} >
              <ListItemText primary="Healthcare Research" />
            </StyledMenuItem>
            <StyledMenuItem  data-test-id="handleScheduleClick9" onClick={()=>{this.handleColor("11");
              this.handleClose("services")}} 
               className={localStorage?.getItem("selectedIndex") == "11" ? "clicked" : ""}>
              <ListItemText primary="Healthcare Innovation & Enterpreneurship" />
            </StyledMenuItem>
          </StyledMenu>
        </div>
        <div>
          <Typography
            onClick={(event) => this.handleClick(event, "help")}
            data-test-id="handleClickHelp"
            className="displayMenuText"
            style={this.getMenuStyle(selectedMenu == 4, valueBorder==4,anchorElHome)}
          >
            Help Center
          </Typography>
          {selectedMenu == 4 ? (
            <p
              style={{
                width: "5px",
                height: "5px",
                borderRadius: "50%",
                backgroundColor: anchorElhelp
                  ? "white"
                  : "rgba(255, 255, 255, 0.4)",
                color: "white",
                marginLeft: "38px",
                marginTop:"-6px"
              }}
            />
          ) : null}
        </div>
      </div>
    );
  };

  renderNav = () => {
    return (
      <Grid container xl={12} className="headerAll" data-testid="nav">
        <Box className="headerMain">
          <Box className="logoContainer">
            <img className="logoTop" src={medEdsmall} alt="medEd_logo" />
          </Box>
          <Grid item xl={12} sm={6} className="displayMenu">
            {this.displayMenu()}
          </Grid>

          <Grid
            item
            xl={12}
            sm={6}
            className="searchContainer"
            data-testid="auth-wrapper"
          >
            
           
            &nbsp;&nbsp;
            <Box className="header" style={{marginTop:'-2px'}}>
            <Box className="showProfile">
              <Box className="profileLetter">
                {this.state.userName?.charAt(0)}
              </Box>
              <div className="textContainer">
              <span className="usrNameTxt" data-testid="signupBtn">
                  {this.state.userName}
                </span>
                <span className="subTextProfile">Medicine</span>
              </div>
              <KeyboardArrowRightIcon
                onClick={this.toggleDropdown}
                className="dropdownIcon"
                data-test-id="dropdownArrow"
                style={{ cursor: 'pointer' }}
              />
              </Box>
           
              </Box>
              <div style={{position:'relative'}}>
              <div className="logoutBtnHeader" onClick={this.openLogoutModal} id="handle-logout" style={{cursor:'pointer',height:'43px',marginTop:'3px'}}>
              <img src={logout}alt="logout" height={'15px'} width={'15px'}/>
          </div>
            </div>
          </Grid>
        </Box>
        <DialogBox isModalOpen ={this.state.isLogout}
        handleCancel={this.openLogoutModal} handleOnAction ={this.handleLogout}/>
      </Grid>
    );
  };

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <Container maxWidth="xl" className="container-header mainheader">
        {this.renderNav()}
      </Container>
      // Customizable Area End
    );
  }
}
