import {
   Box, 
   Typography
   } from "@material-ui/core";
import React from "react";
import InstitutionalHeader from "../../navigationmenu/src/InstitutionalHeader.web";
import { 
  Avatar 
} from "@mui/material";
import "./studentdetail.css";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EngagementHistory from "./EngagementHistory.web";
import ApiIntegrationController,{
  Props
} from "../../apiintegration/src/ApiIntegrationController";
import { arrowDown,checkPNG,  courserate, skipCancelPNG } from "./assets";
import TopNav from "./TopNav.web";

export default class StudentDetail extends ApiIntegrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
   mapCourseProgress = (item:any) => {
    return {
      course: item.attributes.name,
      completed: `${item.attributes.completed}%`,
      lastActivity: this.formatShortDate(item.attributes.last_activity),
      finalScore: item.attributes.final_score.toString(),
      image:  item.attributes.image?.url ||''
    }
  };
 averageScore = [{
    course: "Cardiovascular",
    score: '86%',
    rate: 48,
    finalScore:'75'
  }, {
    course: "Cardiovascular",
    score: '86%',
    rate: 55,
    finalScore:'75'
  },
  {
    course: "Cardiovascular",
    score: '86%',
    rate: 70,
    finalScore:'75'
  }]
  handleColor=(rate:any)=>{
    if(rate>=0 && rate<=49){
     return 'rgba(248, 113, 113, .4)';
    }
    else if(rate>=50 && rate<=69){
     return 'rgba(245, 158, 11, .4)'
    }
    return 'rgba(114, 239, 221, 0.4)'
  }
  getStudentStatus = (status: string) => {
    return (  <div
                            style={{
                              backgroundColor: status === "Active" ? "#2F515B" : "#4A3746",
                              color: status === "Active" ? "#72EFDD" : '#F87171',
                              padding: "2px",
                              borderRadius: "20px",
                              textAlign: "center",
                              fontWeight: "400",
                              fontFamily: 'Poppins',
                              position: "relative",
                              width: status === "Active" ? '82px' : '94px',
                            }}
                          >
                            <span style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              gap: '6px',
                              textAlign: 'center',
                            }}>{status === "Active" ? <img src={checkPNG} height={'13px'} width={'12px'} /> : <img src={skipCancelPNG} height={'13px'} width={'12px'}/>} {status}</span>
                          </div>)
  }
   formatShortDate = (isoString:string) => {
    const dateObj = new Date(isoString);
    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const year = String(dateObj.getFullYear()).slice(-2);
    return `${day}/${month}/${year}`;
  };
  returnTableBody = (item:any) =>{
    const row = this.mapCourseProgress(item)
    return (
      <>
      <TableRow
      className="MuiTableRow-root"
        key={row.course}
        style={{
          borderTopLeftRadius:'20px',borderBottomLeftRadius:'20px',
          borderTopRightRadius:'20px',borderBottomRightRadius:'20px',
          height: "52px",
          boxShadow: 'inset 1px 1px 1px rgba(51, 65, 85, 1), inset -1px -1px 1px rgba(51, 65, 85, 1), inset 1px -1px 1px rgba(51, 65, 85, 1), inset -1px 1px 1px rgba(51, 65, 85, 1)',
          backgroundColor: "rgba(24, 34, 50, 1)",
          marginBottom: "10px",
          padding: 0,
        }}>
        <TableCell
        className="MuiTableCell-root"
          style={{
            ...webStyle.tableCellBody,
            borderRadius: "20px 0 0 20px",
            color: "rgba(255, 255, 255, 1)",
            fontFamily: "Poppins",
            borderBottom: "none",
            height: '100%',
            padding: 0,
          }}
          align="left"
        >

          <div style={{ display: "flex", alignItems: "center", justifyContent: 'left', gap: '30px' }}>
            <img
                style={{
                  width: "40px", height: "40px", borderRadius: "15px", position: 'relative', marginLeft: '5px',
                  border: '1px solid #919D9F',
                }}
                src={row.image || courserate}
                alt="Course Icon"
              />
            <span style={{  fontFamily: "Poppins"}}>{row.course}</span>
          </div>
        </TableCell>
        <TableCell
          style={{
            ...webStyle.tableCellBody,
            textAlign: 'left',
            marginLeft: '20px',
            color: 'rgba(255, 255, 255, 1)',
            fontFamily: 'Poppins',
            borderBottom: 'none'
          }}
          align='center'>
          <div
            style={{
              display: "flex",
              marginRight: '30px',
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                position: "relative",
            }}
            >
              <div className="donut-data" style={{ fontFamily: "Poppins", fontSize:'14px' }}>
                {row.completed}
              </div>
            </div>
          </div>
        </TableCell>
        <TableCell
          style={{
            ...webStyle.tableCellBody,
            color: 'rgba(255, 255, 255, 1)',
            fontFamily: 'Poppins',
            borderBottom: 'none',
          }} align='right'
        >
          <div style={{ display: "flex", justifyContent: "end", alignItems: "center", marginRight:'15px'}}>
            <div style={{borderRadius: '20px', height: '28px', width: '70px', alignItems: 'center', display: 'flex', justifyContent: 'center', textAlign: 'left', fontFamily: 'Poppins', }}>
              {row.lastActivity}
            </div>
          </div>
        </TableCell>
        <TableCell
          style={{
            ...webStyle.tableCellBody,
            borderRadius: "0 20px 20px 0",
            color: 'rgba(255, 255, 255, 1)',
            fontFamily: 'Poppins',
            borderBottom: 'none',
          }} align='right'
        >
          <div style={{ display: "flex", justifyContent: "end", alignItems: "center", marginRight:'15px'}}>
            <div style={{ backgroundColor: this.handleColor(row.finalScore), borderRadius: '20px', height: '28px', width: '70px', alignItems: 'center', display: 'flex', justifyContent: 'center', textAlign: 'left', fontFamily: 'Poppins', }}>
              {row.finalScore}/100
            </div>
          </div>
        </TableCell>
      </TableRow>
      <br/>
    </>
    )
  }
   formatDateTime = (isoString:string) => {
    const dateObj = new Date(isoString);
    const date = dateObj.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    });
    const time = dateObj.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
    });
    return `${date} • ${time}`;
  };
  render() {
    const studentData = this.state.studentEngagementData
   const courseData  = studentData?.attributes?.course_progress?.data || []
    return (
      <>
        <Box style={webStyle.backgroundCLr} className="course-page">
          <InstitutionalHeader navigation={this.props?.navigation} id={"3"} />
          <TopNav />
          <Box
            className="student-detail"
            style={{
              marginTop: "40px",
              border: "1px solid rgba(30, 41, 59, 1)",
              margin:"0 auto 1% !important"
            }}
          >
            <Box style={{display:'flex',flexDirection:'column',gap:'28px'}}>
              <Box style={webStyle.rowDirection}>
                <Box>
                  <Typography
                    variant="h5"
                    style={{
                      ...webStyle.subHeadingText,
                      marginLeft: "10px",
                      marginBottom: "8px",
                    }}
                  >
                    Student Profile
                  </Typography>
                </Box>
                <Box>
                 {this.getStudentStatus(studentData?.attributes?.status)}
                </Box>
              </Box>
              <Box style={webStyle.rowDirection}>
                <Box style={{ display: "flex", gap: "24px" }}>
                  {/* imgate */}
                  <Box>
                    <Avatar
                      sx={{ width: 76, height: 76, backgroundColor: "#404953" }}
                      src={studentData?.attributes?.image}
                    >
                      {studentData?.attributes?.student_name[0]?.toUpperCase()}
                    </Avatar>
                  </Box>
                  {/* detail */}
                  <Box>
                    <Typography style={{fontFamily:'Poppins',fontSize:'24px', color:'#ffff', fontWeight:'700'}}>{studentData?.attributes?.student_name}</Typography>
                    <Typography style={{fontFamily:'Poppins',fontSize:'16px', color:'#ffff', fontWeight:'300'}}>{studentData?.attributes?.email}</Typography>
                    <Typography style={{fontFamily:'Poppins',fontSize:'12px', color:'#ffff', fontWeight:'300',opacity:0.5}}>Enrollment: {this.formatDateTime(studentData?.attributes?.enrollment)}</Typography>
                  </Box>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                  }}
                >
                 <div style={{zIndex:2}}>
                      <span className="btn-login-wrapper" style={{right:'18%'}}>
                        <button
                          className="login-btn"
                          style={{
                            color: "white",
                            fontSize: "14px",
                            fontWeight: "800",
                            fontFamily: 'Raleway',
                            border: 'none',
                            marginLeft: '20px',
                            marginTop: '2px',
                            cursor:'pointer'

                          }}
                          type="button"
                          onClick={this.backToStudentList}
                        >
                          Back to student list
                        </button>
                      </span>
              </div>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
          style={{
            display:'flex',
             margin: "0 auto 5%",
             gap:'32px',
             maxWidth:'84%'

          }}
          >
          <Box
            className="student-detail"
            style={{
              marginTop: "40px",
              border: "1px solid rgba(30, 41, 59, 1)",
              width:'60%',
              maxHeight:'fit-content',
            }}
          >
            <Box>
              <Typography variant="h5"
                    style={{
                      ...webStyle.subHeadingText,
                      marginLeft: "10px",
                      marginBottom: "32px",
                    }}>Course Progress</Typography>
              <TableContainer style={{ borderRadius: '8px 8px 0px 0px', marginBottom: '55px',backgroundColor:'rgb(18, 28, 40)' }}>
          <Table aria-label='simple table'>
            <TableHead >
              <TableRow >

                <TableCell style={{ ...webStyle.tableCellRow, borderBottom: 'none', opacity: '100%', textTransform: 'uppercase', color: 'rgba(255, 255, 255, 1)', fontFamily: 'Raleway', left: '80px', position: 'relative' }} align='left'>Course Name</TableCell>
                <TableCell style={{ ...webStyle.tableCellRow, borderBottom: 'none', textTransform: 'uppercase', position: 'relative', textAlign: 'right', color: 'rgba(255, 255, 255, 1)', fontFamily: 'Raleway' }} align='right'>Completed</TableCell>
                <TableCell style={{ ...webStyle.tableCellRow, borderBottom: 'none', textTransform: 'uppercase', textAlign: 'right', color: 'rgba(255, 255, 255, 1)', fontFamily: 'Raleway' }} align='right'>Last activity</TableCell>
                <TableCell style={{ ...webStyle.tableCellRow, borderBottom: 'none', textTransform: 'uppercase', textAlign: 'right', color: 'rgba(255, 255, 255, 1)', fontFamily: 'Raleway' }} align='right'>Final score</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {courseData.slice(0,this.state.viewAll ? courseData.length : 5).map((row: any) => (
                <>{this.returnTableBody(row)}</>
              ))}
            </TableBody>
          </Table>
          {courseData?.length>5 &&(<Box style={{display:'flex',justifyContent:'center', gap:'8px',alignItems:'center', marginTop:'12px'}}>
            <Typography style={{
              color: 'rgba(100, 116, 139, 1)',
              fontFamily: 'Raleway',
              fontSize: '14px',
              fontWeight: 800,

            }}>{!this.state.viewAll ? 'View all':'View less'}</Typography>
            <span test-id='viewAllBtn1' onClick={() => this.setState({viewAll:!this.state.viewAll})} style={ this.state.viewAll ? {transform:'rotate(180deg)', cursor:'pointer'} : {cursor:'pointer'}}><img src={arrowDown} /></span>
          </Box>)}
        </TableContainer>
            </Box>
          </Box>
          <Box
            className="student-detail"
            style={{
              marginTop: "40px",
              border: "1px solid rgba(30, 41, 59, 1)",
              width:'40%'
            }}
          >
            <EngagementHistory engagementHistoryData={this.state.studentEngagementHistoryData} studentName = {studentData?.attributes?.student_name} />
            <Box />
          </Box>
          </Box>
        </Box>
      </>
    );
  }
}

const webStyle = {
  backgroundCLr: {
    backgroundColor: "rgb(28, 34, 53)",
    minHeight: "100vh",
  },
  tableCellRow: {
    color: '#FFFFFF',
    width: 'fit-content',
    height: '22px',
    fontFamily: 'Raleway',
    fontSize: '12px',
    opacity: '60%',
    fontWeight: 400

  },
  tableCellBody: {
    width: 'fit-content',
    height: '22px',
    color: ' rgba(255, 255, 255, 1)',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWright: 300,
  },
  text: {
    fontWeight: "bold",
    marginLeft: "10px",
  },
  rowDirection: {
    display: "flex",
    justifyContent: "space-between",
  },
  subHeadingText: {
    fontFamily: "Raleway",
    fontSize: "20px",
    fontWeight: 700,
    color: "rgba(255,255,255,1)",
  },
};
